import fetch from './FetchInterceptor'

const contactUsService = {}
const apiRoute = '/contact-us';


  
  contactUsService.create = async function (data) {
    try {
      const res = await fetch({
        url: `${apiRoute}/create`,
        method: 'post',
        data: data,
      })
      return res
    } catch (err) {
      console.log(err, 'show-err')
    }
  }
  
  

export default contactUsService;