/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import lightImg from "../assets/images/svg/light.svg";
import aboutOne from "../assets/images/all-img/about1.svg";
import targetImg from "../assets/images/svg/target.svg";
import { useNavigate } from "react-router-dom";
const About = ({ data }) => {
  console.log("about data", data);

  const navigate = useNavigate();

  return (
    <>
      <div className="about-area section-padding">
        <div className="container">
          <div className="grid lg:grid-cols-2 grid-cols-1 gap-10">
            <div>
              {/* <img src={aboutOne} alt="aboutOne" className=" mx-auto" /> */}
              <img
                src={data?.listingItems[0]?.image}
                alt="aboutOne"
                className=" mx-auto"
              />
            </div>
            <div>
              {data?.showTitle && (
                <div className="mini-title">{data?.title}</div>
              )}
              <h4 className="column-title ">
                {/* Who We&nbsp;
              <span className="shape-bg" style={{ color: "#011CBE" }}>
                Are
              </span> */}
                {data?.listingItems[0]?.name}
              </h4>
              <div
                dangerouslySetInnerHTML={{
                  __html: data?.listingItems[0]?.homeDescription,
                }}
              ></div>
              <ul className=" list-item space-y-6">
                <li className="flex">
                  <div className="flex-none mr-6">
                    {/* <div className="h-[72px] w-[72px] rounded-full">
                    <img
                      src={lightImg}
                      alt="lightImg"
                      className=" w-full h-full object-cover rounded-full block"
                    />
                  </div> */}
                  </div>
                  <div className="flex-1">
                    <div
                      className="bullet-point"
                      dangerouslySetInnerHTML={{
                        __html: data?.listingItems[0]?.description,
                      }}
                    ></div>
                  </div>
                </li>
                <li className="flex">
                  <div className="flex-none mr-6">
                    {/* <div className="h-[72px] w-[72px] rounded-full">
                    <img
                      src={targetImg}
                      alt=""
                      className=" w-full h-full object-cover rounded-full block"
                    />
                  </div> */}
                  </div>
                  {/* <div className="flex-1">
                  <h4 className=" text-xl mb-1">Our Vision</h4>
                  <div>
                    To be the leading institute driving transformative career
                    success and lifelong learning opportunities globally.
                  </div>
                </div> */}
                </li>
              </ul>
              {/* <div className="pt-8">
              <a href="#" className=" btn btn-primary" onClick={()=>navigate('/about')}>
                Read More Us
              </a>
            </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
