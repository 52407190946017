import fetch from './FetchInterceptor'

const settingsService = {}
const apiRoute = '/adminSettings';

settingsService.getAll = async function (query = '') {
    try {
        const res = await fetch({
            url: `${apiRoute}/get/all${query}`,
            method: 'get'
        });
        return res;
    } catch (err) {
        console.log(err, 'show-err');
        return err
    }
}


export default settingsService;