/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { blogsData, teamData } from "../../constant/dummyData";
import { playButton, reviewStars } from "../../constant/images";
import ReactPlayer from "react-player";
import { Modal } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import testimonialService from "../../services/testimonials";
import LoadingSpinner from "../../assets/svgs/LoadingSpinner";

const HomeVideotestimonials = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [showAll, setShowAll] = useState(false);
  const location = useLocation();

  const handlePlayClick = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const [testimonials, setTestimonials] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const getTestimonials = async () => {
    setLoading(true);
    try {
      const data = await testimonialService.getAll(`?testimonialType=VIDEO`);
      console.log("fetched data", data);
      if (data?.code == "ERR_BAD_RESPONSE" || data?.code == "ERR_BAD_REQUEST") {
        setTestimonials([]);
        setLoading(false);
        return; // Exit early
      }
      if (data?.data) {
        setTestimonials(data?.data);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getTestimonials();
  }, []);

  return (
    <>
      <div className="bottom-padding-home">
        <div className="container">
          <div className="text-center">
            <div className="mini-title">Video Testimonials</div>
            {/* <div className="column-title ">
          Happy Professionals, <span className="shape-bg">Successful</span><span className="text-secondary">&nbsp;Futures</span>
          </div> */}
          </div>
          {/* <div className="grid xl:grid-cols-4 lg:grid-cols-3  md:grid-cols-2 grid-cols-1 gap-[30px] pt-10">
          {testimonials?.filter((item)=>item?.testimonialType == "VIDEO")?.map((item, index) => (
            <div
              className=" bg-white shadow-box3 rounded-[8px] transition-all duration-100 pt-10 pb-[28px] px-6 text-center hover:shadow-box4
            border-t-4 border-transparent hover:border-secondary "
              key={index}
            >
              <div className="w-[170px] h-[170px]  relative mx-auto mb-8">
                <img
                  src={item.profileImage}
                  alt=""
                  className=" w-full h-full object-cover rounded-corner"
                />
              </div>
              <div className="course-content">
                <h4 className=" lg:text-2xl text-1xl mb-1 font-bold">
                  {item.name}
                </h4>
                <div>{item.title}</div>
              </div>
            </div>
          ))}
        </div> */}
          {!loading && testimonials?.length > 0 && (
            <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-[30px] pt-7">
              {testimonials
                ?.filter((item) => item?.testimonialType === "VIDEO")
                ?.slice(0, showAll ? testimonials.length : 6) // Conditionally slice
                ?.map((blog, index) => {
                  // Extract the video thumbnail for YouTube videos
                  const getYouTubeThumbnail = (url) => {
                    const videoIdMatch = url.match(
                      /(?:https?:\/\/(?:www\.)?youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|(?:https?:\/\/)?(?:www\.)?youtu\.be\/)([a-zA-Z0-9_-]{11})/
                    );
                    return videoIdMatch
                      ? `https://img.youtube.com/vi/${videoIdMatch[1]}/hqdefault.jpg`
                      : null;
                  };

                  const videoThumbnail =
                    getYouTubeThumbnail(blog?.videoUrl) || blog.profileImage;

                return (
                  <div
                    className="bg-white shadow-box5 rounded-[8px] transition duration-100 hover:shadow-box3"
                    key={index}
                  >
                    <div className="course-thumb h-auto rounded-t-[8px] relative">
                      <img
                        src={videoThumbnail}
                        alt=""
                        className="w-full h-auto object-cover rounded-t-[8px]"
                      />
                      <img
                        class="cursor-pointer absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-blue-500 text-white p-4 rounded-lg"
                        src={playButton}
                        onClick={() => {
                          setVideoUrl(blog?.videoUrl);
                          handlePlayClick();
                        }}
                      />
                    </div>
                    <div className="course-content p-5 flex flex-col items-center">
                      <h4 className="lg:text-2xl text-1xl mb-1 font-bold text-center">
                        {blog.name}
                      </h4>
                      <div className="text-center">{blog.position}</div>
                      {/* <img src={reviewStars} className="mt-3" /> */}
                    </div>
                  </div>
                );
              })}
            <Modal
              open={isModalOpen}
              onCancel={handleModalClose}
              footer={null}
              centered
              width="800px"
            >
              <ReactPlayer
                url={videoUrl}
                controls
                playing={isModalOpen} // Auto-play when modal is open
                width="100%"
                height="450px"
              />
            </Modal>
          </div>
      )}
        </div>
        {!showAll && testimonials?.length > 0 && (
          <div className="text-center pt-14">
            <a
              className=" btn btn-primary inline-flex items-center  space-x-[10px] cursor-pointer"
              onClick={() => {
                if (location.pathname == "/") {
                  navigate("/video-testimonials");
                } else {
                  setShowAll(true);
                }
              }}
            >
              <span>{location.pathname != "/" ? "Load More" : "View All"}</span>
              {location.pathname != "/" ? (
                <span className=" relative top-1">
                  <iconify-icon icon="ion:reload-outline"></iconify-icon>
                </span>
              ) : (
                ""
              )}
            </a>
          </div>
        )}
      </div>
    </>
  );
};

export default HomeVideotestimonials;
