import React, { useEffect, useRef, useState } from "react";
import ReactPaginate from "react-paginate";

const Pagination = ({
  currentPage,
  setCurrentPage,
  totalItemsCount,
  itemsCountPerPage,
}) => {
  const [activePage, setActivePage] = useState(currentPage);

  useEffect(() => {
    setCurrentPage(activePage);
  }, [activePage]);

  const handlePageChange = (pageNumber) => {
    console.log(`active page is`, pageNumber);
    setActivePage(pageNumber.selected + 1);
  };


  return (
    <div className="flex justify-center">
      <ReactPaginate
        pageCount={Math.ceil(totalItemsCount / itemsCountPerPage)}
        pageRangeDisplayed={3}
        marginPagesDisplayed={1}
        forcePage={currentPage - 1} //to show the current page taken from url params
        onPageChange={handlePageChange}
        containerClassName="mt-14 pagination flex justify-center space-x-3 items-center"
        pageClassName="inline-flex"
        pageLinkClassName="flex w-12 h-12 flex-col items-center justify-center bg-[#ECECEC] rounded font-semibold"
        activeClassName="flex w-12 h-12 flex-col items-center justify-center bg-primary-imp text-white rounded font-semibold"
        previousLabel={
          <div className="flex w-12 h-12 flex-col items-center justify-center bg-[#ECECEC] rounded font-semibold">
            <iconify-icon
              icon="heroicons:chevron-double-left-20-solid"
              class="text-2xl"
            ></iconify-icon>
          </div>
          //   <img
          //     className="previous-arrow"
          //     src="/news-img/icons/pagination-left-arrow.svg"
          //     alt="Previous"
          //   />
        }
        nextLabel={
          <div className="flex w-12 h-12 flex-col items-center justify-center bg-[#ECECEC] rounded font-semibold">
            <iconify-icon
              icon="heroicons:chevron-double-right-20-solid"
              class="text-2xl"
            ></iconify-icon>
          </div>
          //   <img
          //     className="previous-arrow"
          //     src="/news-img/icons/pagination-right-arrow.svg"
          //     alt="Previous"
          //   />
        }
        breakLabel={"..."}
      />
    </div>
  );
};

export default Pagination;
