import React from "react";
import { InlineShareButtons } from "sharethis-reactjs";

const ShareButtons = () => {
  return (
    <div className="mt-10">
      <h5 className="mb-2 course-description">Share this page</h5>
      <InlineShareButtons
        config={{
          alignment: "left", // alignment of buttons (left, center, right)
          color: "social", // color of buttons (social, white)
          enabled: true, // enable/disable the buttons
          font_size: 16, // font size for the share count
          labels: "null", // button labels (cta, counts, null)
          language: "en", // language for the buttons
          networks: [
            // Social networks
            "facebook",
            "linkedin",
            "whatsapp",
            "instagram",
            "twitter",
            "copy",
          ],
          padding: 12, // padding within buttons
          radius: 8, // corner radius of buttons
          size: 40, // size of buttons
          show_total: false, // show/hide the total share count
        }}
      />
    </div>
  );
};

export default ShareButtons;
