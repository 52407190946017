import React from "react";
import {
  about5,
  h,
  videoIcon,
  whiteWebIcon,
  learnAnything,
} from "../../constant/images";

const About = () => {
  return (
    <div className="about-area  section-padding-top pb-16 relative z-[1]">
      <div className=" absolute right-[7%] top-[20%] z-[-1] hidden xl:block">
        <img src={h} alt="" />
      </div>
      <div className="container">
        <div className="grid grid-cols-12 xl:gap-[70px] lg:gap-10 gap-6">
          <div className="xl:col-span-7 lg:col-span-6 col-span-12">
            <img src={learnAnything} alt="" />
          </div>
          <div className="xl:col-span-5 lg:col-span-6 col-span-12 ">
            {/* <div className="mini-title">About Edumim</div> */}
            <h4 className="column-title ">
              You Can Learn Anything, Anytime From{" "}
              <span className="shape-bg">Anywhere</span>
            </h4>
            <div>
              Gain knowledge and skills anytime, anywhere, with expert-led
              courses tailored for you.
            </div>
            <ul className=" list-item space-y-6 pt-8">
              <li className="flex">
                <div className="flex-none mr-6">
                  <div className="h-20 w-20 rounded-full bg-white  shadow-box10 flex flex-col justify-center items-center">
                    <img src={videoIcon} alt="" className="" />
                  </div>
                </div>
                <div className="flex-1">
                  <h4 className=" text-xl mb-1">
                    Online Classes by Expert Educators
                  </h4>
                  <div>
                    Learn directly from seasoned professionals in the field.
                  </div>
                </div>
              </li>
              <li className="flex">
                <div className="flex-none mr-6">
                  <div className="h-20 w-20 rounded-full bg-white  shadow-box10 flex flex-col justify-center items-center">
                    <img src={whiteWebIcon} alt="" className=" " />
                  </div>
                </div>
                <div className="flex-1">
                  <h4 className=" text-xl mb-1">
                    Flexible Learning Opportunities
                  </h4>
                  <div>
                    Study at your pace with anytime, anywhere access to
                    resources.
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
