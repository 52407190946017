/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { courseData, courseDataInPage } from "../constant/dummyData";
import Course from "./Course";
import { Link, useNavigate } from "react-router-dom";
import StarRating from "./Pages/StarRating";
const Courses = ({ data }) => {
  console.log("courses data is", data);
  const navigate = useNavigate();
  const [redirectValue, setRedirectValue] = useState("");
  const [buttonText, setButtonText] = useState("");
  const [buttonRedirect, setButtonRedirect] = useState("");

  useEffect(() => {
    if (data?.categoryName == "Blogs") {
      setRedirectValue("blogs/blog-detail");
      setButtonText("View All Blogs");
      setButtonRedirect("blogs");
    }
    if (data?.categoryName == "Corporate Solutions") {
      setRedirectValue(`corporate-solutions-detail`);
      setButtonText("View All Solutions");
      setButtonRedirect("corporate-solutions");
    }
    if (data?.categoryName == "Courses") {
      setRedirectValue(`course-detail`);
      setButtonText("View All Courses");
      setButtonRedirect("courses");
    }
  }, [data]);

  const redirect = (category, slug) => {
    console.log("entered here", category, slug);
    if (category == "Blogs") {
      return `/blogs/blog-detail/${slug}`;
    }
    if (category == "Corporate Solutions") {
      return `/corporate-solutions-detail/${slug}`;
    }
    if (category == "Courses") {
      return `/course-detail/${slug}`;
    }
  };

  return (
    <div className=" course-section-padding">
      <div className="container">
        {data?.showTitle && (
        <div className="text-center">
          {/* <div className="mini-title">Popular Courses</div> */}
          <div className="column-title ">{data?.title}</div>
        </div>
        )}
        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-[30px] pt-10">
          {data?.listingItems?.slice(0, 6).map((item, index) => (
            <Link
              className=" bg-white shadow-box2 rounded-[8px] transition duration-100 hover:shadow-sm"
              to={`/${redirectValue}/${item?.slug}`}
              key={`${item.id}${index}`}
            >
              <div
                onClick={() => {
                  const url = redirect(item?.categoryName, item?.slug);
                  console.log("redirect url", url);
                  navigate(url);
                }}
                // onClick={() => {
                //   localStorage.setItem("selectedCourse", item.url);
                //   selectCourse(item.url);
                // }}
                className="course-thumb h-auto rounded-t-[8px]  relative"
              >
                <img
                  src={item.image}
                  alt=""
                  className=" w-full h-auto object-cover rounded-t-[8px]"
                />
              </div>
              <div className="course-content p-8">
                <h4 className=" text-xl mb-3 font-bold h-[60px]">
                  {item.name}
                </h4>
                <div className="flex justify-between items-center mb-3">
                  {/* <div className="text-secondary font-bold text-2xl ">
                    {item.price}
                  </div> */}
                  {/* <img src={reviewStars} className="w-[150px]" /> */}
                  {/* <StarRating rating={4.5} /> */}
                </div>

                {/* <div className="flex justify-between  flex-wrap space-y-1 xl:space-y-0">
                    <span className=" flex items-center space-x-2 mr-3">
                      <img src={file} alt="" />
                      <span>2 Lessons</span>
                    </span>
                    <span className=" flex items-center space-x-2 mr-3">
                      <img src={clock} alt="" />
                      <span> {item.time} </span>
                    </span>
                    <span className=" flex items-center space-x-2 ">
                      <img src={star} alt="" />
                      <span>{item.ratings}</span>
                    </span>
                  </div> */}
              </div>
            </Link>
          ))}
          {/* {courseData.map((course, index) => (
            <Link to={`/course-detail/${course.slug}`}>
              <Course course={course} key={index} index={index} />
            </Link>
          ))} */}
        </div>
        <div className="text-center lg:pt-16 pt-10 cursor-pointer">
          <a
            className=" btn btn-primary"
            onClick={() => navigate(`/${buttonRedirect}`)}
          >
            {buttonText}
          </a>
        </div>
      </div>
    </div>
  );
};

export default Courses;
