import fetch from './FetchInterceptor'

const policyService = {}
const apiRoute = '/information-policy';



policyService.getBySlug = async function (slug) {
    try {
        const res = await fetch({
            url: `${apiRoute}/getBySlug/${slug}`,
            method: 'get',
        })
        return res
    } catch (err) {
        console.log(err, 'show-err')
    }
}

policyService.getPolicy = async function (query = '') {
    try {
        const res = await fetch({
            url: `${apiRoute}/getAll/public`,
            method: 'get'
        });
        return res;
    } catch (err) {
        console.log(err, 'show-err');
        return err
    }
}



export default policyService;