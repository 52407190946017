import fetch from './FetchInterceptor'

const pagesService = {}
const apiRoute = '/Pages';

pagesService.getAll = async function (query = '') {
    try {
        const res = await fetch({
            url: `${apiRoute}/getAll${query}`,
            method: 'get'
        });
        return res;
    } catch (err) {
        console.log(err, 'show-err');
        return err
    }
}

pagesService.getById = async function (id) {
    try {
      const res = await fetch({
        url: `${apiRoute}/get/${id}`,
        method: 'get',
      })
      return res
    } catch (err) {
      console.log(err, 'show-err')
    }
  }

  pagesService.getBySlug = async function (slug) {
    try {
      const res = await fetch({
        url: `${apiRoute}/get/slug/${slug}`,
        method: 'get',
      })
      return res
    } catch (err) {
      return err
      console.log(err, 'show-err')
    }
  }
  
  pagesService.create = async function (data) {
    try {
      const res = await fetch({
        url: `${apiRoute}/create`,
        method: 'post',
        data: data,
      })
      return res
    } catch (err) {
      console.log(err, 'show-err')
    }
  }
  
  
  pagesService.edit = async function (id, data) {
    try {
      const res = await fetch({
        url: `${apiRoute}/update/${id}`,
        method: 'put',
        data: data,
      })
      return res
    } catch (err) {
      console.log(err, 'show-err')
    }
  }

pagesService.delete = async function (id) {
    try {
        const res = await fetch({
            url: `${apiRoute}/delete/${id}`,
            method: 'delete'
        });
        return res;
    } catch (err) {
        console.log(err, 'show-err');
    }
}

export default pagesService;