import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCreative, Navigation } from "swiper";
import { t1 } from "../../constant/images";
import "swiper/css/bundle";
import "swiper/css";
import "swiper/css/effect-creative";
import "swiper/css/navigation";
import { useNavigate, useNavigationType } from "react-router-dom";
import testimonialService from "../../services/testimonials";
import LoadingSpinner from "../../assets/svgs/LoadingSpinner";

const HomeTestimonials = ({data}) => {
  console.log('home testimonial data', data)
  const prevBtn = useRef(null);
  const nextBtn = useRef(null);
  const [loading, setLoading] = useState(false);

  const [testimonials, setTestimonials] = useState([]);
  const navigate = useNavigationType();

  const getTestimonials = async () => {
    setLoading(true);
    try {
      const data = await testimonialService.getAll(`?testimonialType=CONTENT`);
      console.log("fetched data", data);
      if (data?.code == "ERR_BAD_RESPONSE" || data?.code == "ERR_BAD_REQUEST") {
        setTestimonials([])
        setLoading(false)
        return; // Exit early
      }
      if(data?.data){
      setTestimonials(data?.data);
      setLoading(false)
      }
    } catch (err) {
      setLoading(false)
    }
  };

  useEffect(() => {
    getTestimonials();
  }, []);

  console.log("testimonials uu", testimonials, loading);

  return (
    <>
      {!loading && testimonials?.length > 0 && (
        <div className="section-padding bg-[url('../images/all-img/section-bg-12.png')] bg-no-repeat bg-cover mt-14">
          <Swiper
            navigation={{
              prevEl: ".slickprev",
              nextEl: ".slicknext",
            }}
            spaceBetween={30}
            loop
            effect={"creative"}
            creativeEffect={{
              prev: {
                shadow: true,
                translate: [0, 0, -400],
                opacity: [0],
              },
              next: {
                translate: ["100%", 0, 0],
              },
            }}
            modules={[Navigation, EffectCreative]}
            className="container"
          >
            {testimonials
              ?.filter((item) => item?.testimonialType === "CONTENT")
              ?.map((testimonial, index) => (
                <SwiperSlide
                  key={index}
                  className="grid lg:grid-cols-1 grid-cols-1 xl:gap-[60px] gap-6 p-8"
                >
                  <div className="text-center testimonial-grid p-5">
                    {data?.showTitle && (
                    <div className="mini-title">{data?.title}</div>
                  )}
                    <h4 className="column-title">
                      {/* Our Talented Students Valuable
                      <span className="shape-bg text-black ml-2">Feedback</span> */}
                      {/* {data?.listingItems[0]?.title} */}
                    </h4>
                    <div className="slider-for mt-10">
                      <div className="single-item">
                        <div>
                          <h3 className="text-2xl font-bold text-black mb-8">
                            {data?.listingItems[0]?.title}
                          </h3>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: testimonial?.content,
                            }}
                            className="mb-8 w-[80%] text-left"
                          ></div>
                          <div>
                            <span className="block font-semibold text-black mb-1">
                              {testimonial?.name}
                            </span>
                            <span className="block font-semibold text-primary">
                              {testimonial?.position}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            <div className="space-x-5 flex justify-center lg:mt-10 mt-8">
              <button
                className="lg:h-[64px] lg:w-[64px] h-12 w-12 flex flex-col items-center justify-center rounded-md bg-white hover:bg-primary
                        hover:text-white shadow-box slickprev text-3xl text-primary"
                ref={prevBtn}
              >
                <iconify-icon icon="heroicons:arrow-left-20-solid"></iconify-icon>
              </button>
              <button
                className="lg:h-[64px] lg:w-[64px] h-12 w-12 flex flex-col items-center justify-center rounded-md bg-white hover:bg-primary
                        hover:text-white shadow-box slicknext text-3xl text-primary"
                ref={nextBtn}
              >
                <iconify-icon icon="heroicons:arrow-right-20-solid"></iconify-icon>
              </button>
            </div>
          </Swiper>
        </div>
      )}
    </>
  );
  
};

export default HomeTestimonials;
