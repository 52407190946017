/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import ok from "../assets/images/svg/ok.svg";
import aboutTwo from "../assets/images/all-img/about2.svg";
import { useNavigate } from "react-router-dom";

const Platform = ({data}) => {

  const navigate = useNavigate();
  console.log('platform data', data)

  return (
    <div className="bg-[url('../images/all-img/section-bg-2.png')] bg-cover bg-no-repeat bg-center section-padding mt-20">
      <div className="container">
        <div className="lg:grid grid-cols-12 gap-[30px]">
          <div className="xl:col-span-6 lg:col-span-6 col-span-12">
            {data?.showTitle && (
            <div className="mini-title">{data?.title}</div>
          )}
            <h4 className="column-title ">
              {/* One Platform,Countless
              <span className="shape-bg">Career</span> Opportunities */}
              {data?.listingItems[0]?.name}
            </h4>
            {/* <div dangerouslySetInnerHTML={{ __html: data?.listingItems[0]?.description }}> */}
            <div className="bullet-point" dangerouslySetInnerHTML={{ __html: data?.listingItems[0]?.homeDescription }}>
            </div>
            {/* <ul className=" space-y-2 pt-8">
              <li className="flex flex-wrap">
                <div className="flex-none mr-3">
                  <div className="">
                    <img src={ok} alt="" />
                  </div>
                </div>
                <div className="flex-1">
                  <h4 className=" text-lg mb-1 font-semibold">
                  100% Student Satisfaction
                  </h4>
                </div>
              </li>
              <li className="flex flex-wrap">
                <div className="flex-none mr-3">
                  <div className="">
                    <img src={ok} alt="" />
                  </div>
                </div>
                <div className="flex-1">
                  <h4 className=" text-lg mb-1 font-semibold">
                  96% Course Completion Rat
                  </h4>
                </div>
              </li>
              <li className="flex flex-wrap">
                <div className="flex-none mr-3">
                  <div className="">
                    <img src={ok} alt="" />
                  </div>
                </div>
                <div className="flex-1">
                  <h4 className=" text-lg mb-1 font-semibold">
                  Expert Mentors & Flexible Learning
                  </h4>
                </div>
              </li>
            </ul> */}
            <div className="pt-8">
              <a onClick={()=>navigate('/courses')} className=" btn btn-primary">
                Explore Our Courses
              </a>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 lg:pt-0 md:pt-10 pt-6">
            <img src={aboutTwo} alt="" className=" mx-auto block w-full" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Platform;
