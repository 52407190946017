import fetch from './FetchInterceptor'

const seoService = {}
const apiRoute = '/pages-seo';



seoService.get = async function (path) {
    try {
        const res = await fetch({
            url: `${apiRoute}/url/${encodeURIComponent(path)}`,
            method: 'get',
        })
        return res
    } catch (err) {
        console.log(err, 'show-err')
    }
}



export default seoService;