/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../../assets/svgs/LoadingSpinner";

const PolicyContent = ({ data }) => {
  const navigate = useNavigate();
  return (
    <div className="nav-tab-wrapper tabs  section-padding">
      <div className="container">
        <div className="grid grid-cols-12 gap-[30px]">
          <div className="lg:col-span-12 col-span-12">
            <div className="bg-[#F8F8F8] rounded-md">
              <div className="px-10 pt-10 pb-10">
                <h3>{data?.data?.title}</h3>
                <p
                  dangerouslySetInnerHTML={{
                    __html: data?.data?.content,
                  }}
                  className="mt-6 course-description"
                ></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PolicyContent;
