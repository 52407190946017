import React, { useRef } from "react";
import mailIcon from "../assets/images/icon/mail.svg";
import ManOne from "../assets/images/banner/man1.svg";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectCreative, Navigation } from "swiper";
import "swiper/css/bundle";
import "swiper/css";
import "swiper/css/effect-creative";
import "swiper/css/navigation";
import { t1 } from "../constant/images";
import useDeviceWidth from "./UseDeviceWidth";
import "swiper/css/autoplay";  // Import autoplay styles

const Banner = ({ data }) => {
  // const navigate = useNavigate();
  // console.log('data coming in banner', data)

  // return (
  //   <section
  //     className={`xl:min-h-screen bg-[url('../images/banner/1.png')] bg-cover bg-no-repeat bg-center overflow-hidden`}
  //   >
  //     <div className="container relative">
  //       <div
  //         style={{ maxWidth: "700px" }}
  //         className="max-w-[570px] xl:pt-[297px] md:pt-20 md:pb-20 pt-28 pb-14 xl:pb-40 space-y-8"
  //       >
  //         <h1>
  //           {/* Empowering{" "}
  //           <span
  //             style={{ color: "#011CBE" }}
  //             className=" text-secondary inline-block bg-[url('../images/banner/shape.svg')]  bg-no-repeat bg-bottom"
  //           >
  //             Futures
  //           </span>{" "}
  //           Through Career Guidance */}
  //           {data?.listingItems[0]?.title}
  //         </h1>
  //         <div className=" plain-text text-gray leading-[30px]">
  //           Join the leading institute for career development and shape your
  //           future today!
  //         </div>
  //         <div className="flex-none">
  //           <button
  //             onClick={() => navigate("/courses")}
  //             style={{ backgroundColor: "#D20639" }}
  //             className="btn btn-primary w-full lg:w-auto"
  //           >
  //             Explore Courses
  //           </button>
  //         </div>
  //       </div>
  //       <div className="imge-box absolute xl:right-[-260px]  hidden xl:block   bottom-0  ">
  //         {/* <img src={ManOne} alt="manOne" /> */}
  //         <img src={data?.listingItems[0]?.webImage} alt="manOne" />
  //       </div>
  //     </div>
  //   </section>
  // );
  const prevBtn = useRef(null);
  const nextBtn = useRef(null);
  const navigate = useNavigate();
  console.log("banner data is", data);
  const width = useDeviceWidth();

  return (
    <div className=" section-padding bg-[url('../images/all-img/section-bg-12.png')]  bg-no-repeat bg-cover">
      <Swiper
        navigation={{
          prevEl: ".slickprev",
          nextEl: ".slicknext",
        }}
        spaceBetween={30}
        autoplay={{
          delay: 5000, // Slide changes every 3 seconds
          disableOnInteraction: false, // Continue autoplay after user interaction
        }}
        loop
        effect={"creative"}
        creativeEffect={{
          prev: {
            shadow: true,
            translate: [0, 0, -400],
            opacity: [0],
          },
          next: {
            translate: ["100%", 0, 0],
          },
        }}
        modules={[Navigation, EffectCreative, Autoplay]}
        className="container"
      >
        {data?.listingItems?.map((banner) => (
          <SwiperSlide className="grid  lg:grid-cols-2 grid-cols-1  xl:gap-[60px] gap-6 items-center">
            <div>
              <div className="slider-nav">
                <div className="single-item">
                  <div className="xl:h-[593px] lg:h-[400px] h-[150px] lg:w-full w-[150px] rounded-md">
                    <img
                      // src={t1}
                      src={banner?.webImage}
                      alt=""
                      className=" object-cover w-full h-full rounded-md"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <h4 className="column-title ">
                {/* Our Tallented Students Valuable
              <span className="shape-bg text-black">Feedback</span> */}
                {banner?.title}
              </h4>
              <div className="slider-for mt-10">
                <div className="single-item">
                  <div>
                    <div
                      dangerouslySetInnerHTML={{ __html: banner?.description }}
                      className="mb-8"
                    ></div>
                    <div className="flex-none">
                      <button
                        onClick={() => navigate("/courses")}
                        style={{ backgroundColor: "#D20639" }}
                        className="btn btn-primary w-full lg:w-auto"
                      >
                        Explore Courses
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
        {/* <SwiperSlide className="grid  lg:grid-cols-2 grid-cols-1  xl:gap-[60px] gap-6">
          <div>
            <div className="slider-nav">
              <div className="single-item">
                <div className="xl:h-[593px] lg:h-[400px] h-[150px] lg:w-full w-[150px] rounded-md">
                  <img
                    src={t1}
                    alt=""
                    className=" object-cover w-full h-full rounded-md"
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="mini-title">Testimonial</div>
            <h4 className="column-title ">
              Our Tallented Students Valuable
              <span className="shape-bg text-black">Feedback</span>
            </h4>
            <div className="slider-for mt-10">
              <div className="single-item">
                <div>
                  <h3 className=" text-2xl font-bold text-black mb-8">
                    “It’s Truly The Best Solution For Me”
                  </h3>
                  <div className="mb-8">
                    There are many variations of passages of Lorem Ipsum
                    available, but the majority have suffered.
                  </div>
                  <div>
                    <span className=" block  font-semibold text-black mb-1">
                      Alfred Helmerich
                    </span>
                    <span className=" block  font-semibold text-primary">
                      Executive Training Manager
                    </span>
                  </div>
                  <br />
                  <div>
                    <span className=" block  font-semibold text-black mb-1">
                      Alfred Helmerich
                    </span>
                    <span className=" block  font-semibold text-primary">
                      Executive Training Director
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide> */}
        {/* <div className="space-x-5 flex justify-center lg:mt-10 mt-8">
          <button
            className="lg:h-[64px] lg:w-[64px] h-12 w-12 flex flex-col items-center justify-center rounded-md bg-white hover:bg-primary
                    hover:text-white shadow-box slickprev text-3xl text-primary"
            ref={prevBtn}
          >
            <iconify-icon icon="heroicons:arrow-left-20-solid"></iconify-icon>
          </button>
          <button
            className="lg:h-[64px] lg:w-[64px] h-12 w-12 flex flex-col items-center justify-center rounded-md bg-white hover:bg-primary
                    hover:text-white shadow-box slicknext text-3xl text-primary"
            ref={nextBtn}
          >
            <iconify-icon icon="heroicons:arrow-right-20-solid"></iconify-icon>
          </button>
        </div> */}
      </Swiper>
    </div>
  );
};

export default Banner;
