import React, { useEffect, useState } from "react";
import Header from "../../Header";
import PageBanner from "../../PageBanner";
import Footer from "../../Footer";
import { useNavigate, useParams } from "react-router-dom";
import pagesService from "../../../services/pages";
import LoadingSpinner from "../../../assets/svgs/LoadingSpinner";
import SEO from "../../SEO/SEO";
import PolicyContent from "./PolicyContent";
import policyService from "../../../services/policy";

const PolicyDetail = () => {
  const params = useParams();
  const [data, setData] = useState();
  const navigate = useNavigate();
  const [policyLoading, setPolicyLoading] = useState(false);
  const [metaData, setMetaData] = useState();

  const getPolicyBySlug = async () => {
    setPolicyLoading(true);
    const data = await policyService.getBySlug(params.policySlug);
    if (data?.status == 404) {
      navigate("/not-found");
      return;
    }
    console.log("fetched course data", data);
    if (data?.data) {
      setData(data?.data);
      setMetaData({
        pageTitle: data?.data?.metatitle,
        pageDescription: data?.data?.metadescription,
        pageKeywords: data?.data?.metakeywords?.join(","),
      });
    }
    setPolicyLoading(false);
  };

  useEffect(() => {
    getPolicyBySlug();
  }, [params.policySlug]);

  return (
    <>
      <SEO seo={metaData && metaData} />
      <Header />
      <PageBanner title={"Policy Details"} pageName="Policy Details" />
      {policyLoading && <LoadingSpinner />}
      {!policyLoading && <PolicyContent data={data} />}
      <Footer />
    </>
  );
};

export default PolicyDetail;
