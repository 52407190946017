import React, { useEffect, useState } from "react";
import Header from "../../Header";
import PageBanner from "../../PageBanner";
import Footer from "../../Footer";
import BlogPost from "./BlogPost";
import { useNavigate, useParams } from "react-router-dom";
import pagesService from "../../../services/pages";
import LoadingSpinner from "../../../assets/svgs/LoadingSpinner";
import SEO from "../../SEO/SEO";

const BlogDetail = () => {
  const params = useParams();
  const [data, setData] = useState();
  const [relatedBlogs, setRelatedBlogs] = useState([]);
  const navigate = useNavigate();
  const [blogLoading, setBlogLoading] = useState(false);
  const [relatedBlogsLoading, setRelatedBlogsLoading] = useState(false);
  const [metaData, setMetaData] = useState();

  const getBlogBySlug = async () => {
    setBlogLoading(true);
    const data = await pagesService.getBySlug(params.blogSlug);
    if (data?.status == 404) {
      navigate("/not-found");
      return;
    }
    console.log("fetched course data", data);
    if (data?.data) {
      setData(data?.data);
      setMetaData({
        pageTitle: data?.data?.metatitle,
        pageDescription: data?.data?.metadescription,
        pageKeywords: data?.data?.metakeywords?.join(","),
      });
    }
    setBlogLoading(false);
  };

  const getRelatedBlogs = async () => {
    setRelatedBlogsLoading(true);
    const data = await pagesService.getAll(
      `?page=1&limit=10&category=6756b8f37a30928e43575ce0`
    );
    if (data?.status == 404) {
      navigate("/not-found");
      return;
    }
    console.log("fetched blogs data", data);
    setRelatedBlogs(
      data?.data?.filter((item) => item?.slug != params.blogSlug)
    );
    setRelatedBlogsLoading(false);
  };

  useEffect(() => {
    getRelatedBlogs();
  }, []);

  useEffect(() => {
    getBlogBySlug();
  }, [params.blogSlug]);

  return (
    <>
      <SEO seo={metaData && metaData} />
      <Header />
      <PageBanner title={"Blog Details"} pageName="Blog Details" />
      {blogLoading && <LoadingSpinner />}
      {!blogLoading && (
        <BlogPost
          data={data}
          relatedBlogs={relatedBlogs}
          relatedBlogsLoading={relatedBlogsLoading}
        />
      )}
      <Footer />
    </>
  );
};

export default BlogDetail;
