// src/contexts/CourseContext.js

import React, { createContext, useState, useContext, useEffect } from 'react';

// Create context
const CourseContext = createContext();

// Create provider component
export const CourseProvider = ({ children }) => {
  const [selectedCourse, setSelectedCourse] = useState(null);
  
  useEffect(()=>{
    const course = localStorage.getItem("selectedCourse");
    if(course){
      setSelectedCourse(course)
    }
  },[])

  // Function to update the selected course
  const selectCourse = (course) => {
    setSelectedCourse(course);
  };

  return (
    <CourseContext.Provider value={{ selectedCourse, selectCourse }}>
      {children}
    </CourseContext.Provider>
  );
};

// Custom hook to use context
export const useCourseContext = () => {
  return useContext(CourseContext);
};
