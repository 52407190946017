import React, { useEffect, useState } from "react";
import { call, mail, map } from "../../constant/images";
import ContactForm from "../ContactForm";
import Footer from "../Footer";
import Header from "../Header";
import PageBanner from "../PageBanner";
import settingsService from "../../services/settings";
import { useNavigate } from "react-router-dom";
import SEO from "../SEO/SEO";

const ContactUs = () => {
  const navigate = useNavigate();
  const [settings, setSettings] = useState();

  const getSettings = async () => {
    const data = await settingsService.getAll();
    console.log("fetched data", data);
    if (data?.status === 404) {
      navigate("/not-found");
      return; // Exit early
    }
    setSettings(data?.data[0]);
  };

  useEffect(() => {
    getSettings();
  }, []);

  return (
    <>
      <SEO />
      <Header />
      <PageBanner title={"Contact Us"} pageName="Contact" />
      <div class="nav-tab-wrapper tabs  section-padding">
        <div class="container">
          <div class=" grid grid-cols-12 gap-[30px]">
            <div class="xl:col-span-5 lg:col-span-6 col-span-12 ">
              <div class="mini-title">Contact Us</div>
              <h4 class="column-title ">
                Get In Touch <span class="shape-bg">Today</span>
              </h4>
              <div>
                We’d love to hear from you! Contact us today for any inquiries,
                feedback, or support, and we’ll get back to you as soon as
                possible.
              </div>
              <ul class=" list-item space-y-6 pt-8">
                <li class="flex">
                  <div class="flex-none mr-6">
                    <div class="">
                      <img src={mail} alt="" class="" />
                    </div>
                  </div>
                  <div class="flex-1">
                    <h4 class=" lg:text-xl text-lg mb-1">Email-Us :</h4>
                    <div
                      className="normal-case"
                      style={{ textTransform: "none" }}
                    >
                      {settings?.email}
                    </div>
                  </div>
                </li>
                {/* <li class="flex">
                  <div class="flex-none mr-6">
                    <div class="">
                      <img src={call} alt="" class="" />
                    </div>
                  </div>
                  <div class="flex-1">
                    <h4 class=" lg:text-xl text-lg mb-1">Call Us:</h4>
                    <div>+88012 2910 1781, +88019 6128 1689</div>
                  </div>
                </li> */}
                <li class="flex">
                  <div class="flex-none mr-6">
                    <div class="">
                      <img src={map} alt="" class="" />
                    </div>
                  </div>
                  <div class="flex-1">
                    <h4 class="lg:text-xl text-lg mb-1">Office :</h4>
                    <div>{settings?.address}</div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="xl:col-span-7 lg:col-span-6 col-span-12">
              <div class="bg-white shadow-box7 p-8 rounded-md">
                <ContactForm />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ContactUs;
