import fetch from './FetchInterceptor'

const testimonialService = {}
const apiRoute = '/testimonials';

testimonialService.getAll = async function (query = '') {
    try {
        const res = await fetch({
            url: `${apiRoute}/getAll/public${query}`,
            method: 'get'
        });
        return res;
    } catch (err) {
        console.log(err, 'show-err');
        return err
    }
}

testimonialService.getById = async function (id) {
    try {
      const res = await fetch({
        url: `${apiRoute}/getById/${id}`,
        method: 'get',
      })
      return res
    } catch (err) {
      console.log(err, 'show-err')
    }
  }

  
  testimonialService.create = async function (data) {
    try {
      const res = await fetch({
        url: `${apiRoute}/create`,
        method: 'post',
        data: data,
      })
      return res
    } catch (err) {
      console.log(err, 'show-err')
    }
  }
  


export default testimonialService;