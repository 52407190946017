import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import HomeOne from "./Components/Home/HomeOne";
import HomeThree from "./Components/Home/HomeThree";
import HomeTwo from "./Components/Home/HomeTwo";
import ErrorPage from "./Components/Pages/404Page";
import AboutOne from "./Components/Pages/AboutOne";
import AboutTwo from "./Components/Pages/AboutTwo";
import BLogStandard from "./Components/Pages/BLogStandard";
import ContactUs from "./Components/Pages/ContactUs";
import Courses from "./Components/Pages/Courses";
import CourseSideBar from "./Components/Pages/CourseSideBar";
import Event from "./Components/Pages/Event";
import EventSingle from "./Components/Pages/EventSingle";
import InstructorDetails from "./Components/Pages/InstructorDetails";
import InstructorOne from "./Components/Pages/InstructorOne";
import InstructorTwo from "./Components/Pages/InstructorTwo";
import SingleBlog from "./Components/Pages/SingleBlog";
import SingleCourse from "./Components/Pages/SingleCourse";
import Thanks from "./Components/Pages/Thanks";
import Testimonials from "./Components/Pages/Testimonials";
import CoursePage from "./Components/Pages/course-details/CoursePage";
import CourseDetails from "./Components/CourseDetails";
import CourseData from "./Components/Pages/course-data/CourseData";
import Blogs from "./Components/Pages/blogs/Blogs";
import BlogDetail from "./Components/Pages/blogs/BlogDetail";
import Videotestimonials from "./Components/Pages/VideotestimonialsMain";
import CorporateSolutions from "./Components/Pages/Corporate-Solutions/CorporateSolutions";
import { createContext, useEffect, useState } from "react"; import PolicyDetail from "./Components/Pages/policy/PolicyDetail";


function App() {
  const [jsonData, setJsonData] = useState([]);
  const homeContext = createContext();

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const response = await fetch(`${API_BASE_URL}home/generate-file`);

        // const data = await response.json();
        // const fileUrl = data.fileUrl;

        const fileUrl =
          "https://iicd-live.s3.ap-south-1.amazonaws.com/home-file.txt";
        // "https://iicd-test.s3.ap-south-1.amazonaws.com/home-file.txt";

        // Fetch the text file
        const textResponse = await fetch(fileUrl, {
          method: "GET",
          headers: {
            "Cache-Control": "no-cache",
          },
          cache: "no-cache",
        });
        console.log("text response is", textResponse);

        const textData = await textResponse.text();
        console.log("text data is", textData);
        const parsedData = JSON.parse(textData);
        console.log("parsed data", parsedData);
        const widgets = parsedData?.widgets;
        console.log("widgets in app.js", widgets);
        // dispatch(constantsActions.toggleConstants({ widgets: parsedData?.widgets }));

        setJsonData(parsedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <homeContext.Provider value={{ jsonData }}>
      <BrowserRouter>
        <div className="font-gilroy font-medium text-gray text-lg leading-[27px]">
          <Routes>
            {/* <Route
            path="/"
            element={<Navigate to={""} />}
          /> */}
            <Route
              path="/"
              element={
                <HomeOne
                  widgets={jsonData?.widgets?.sort(
                    (a, b) => a.priority - b.priority
                  )}
                />
              }
            />
            <Route path="/home-two" element={<HomeTwo />} />
            <Route path="/home-three" element={<HomeThree />} />
            <Route path="/about" element={<AboutOne />} />
            <Route path="/testimonials" element={<Testimonials />} />
            <Route path="/video-testimonials" element={<Videotestimonials />} />
            <Route path="/about-two" element={<AboutTwo />} />
            <Route path="/instructor" element={<InstructorOne />} />
            <Route path="/instructor-two" element={<InstructorTwo />} />
            <Route path="/instructor-details" element={<InstructorDetails />} />
            <Route path="/event" element={<Event />} />
            <Route path="/event-single" element={<EventSingle />} />
            <Route path="/error" element={<ErrorPage />} />
            <Route path="/courses" element={<Courses />} />
            <Route path="/courses-sidebar" element={<CourseSideBar />} />
            <Route
              path="/corporate-solutions"
              element={<CorporateSolutions />}
            />
            {/* <Route
            path="/single-course/:slug"
            element={<SingleCourse />}
          /> */}
            <Route path="/single-course" element={<SingleCourse />} />
            <Route path="/course-detail/:courseId" element={<CoursePage />} />
            <Route
              path="/corporate-solutions-detail/:courseId"
              element={<CoursePage />}
            />
            {/* <Route
            path="/course-detail/applied-banking-and-banking-operations"
            element={<CoursePage pageId={"applied-banking-and-banking-operations"} />}
          />
          <Route
            path="/course-detail/placement-training"
            element={<CoursePage pageId={"placement-training"} />}
          />
          <Route
            path="/course-detail/cyber-security-with-ethical-hacking"
            element={<CoursePage pageId={"cyber-security-with-ethical-hacking"} />}
          />
          <Route
            path="/course-detail/cloud-computing-with-amazon-web-services"
            element={<CoursePage pageId={"cloud-computing-with-amazon-web-services"} />}
          />
          <Route
            path="/course-detail/business-presentation"
            element={<CoursePage pageId={"business-presentation"} />}
          />
          <Route
            path="/course-detail/sales-training"
            element={<CoursePage pageId={"sales-training"} />}
          />
          <Route
            path="/course-detail/data-analytics"
            element={<CoursePage pageId={"data-analytics"} />}
          />
          <Route
            path="/course-detail/devops"
            element={<CoursePage pageId={"devops"} />}
          />
          <Route
            path="/course-detail/data-science&Ai-ml"
            element={<CoursePage pageId={"data-science&Ai-ml"} />}
          />
          <Route
            path="/course-detail/python-full-stack"
            element={<CoursePage pageId={"python-full-stack"} />}
          />
          <Route
            path="/course-detail/business-english"
            element={<CoursePage pageId={"business-english"} />}
          />
          <Route
            path="/course-detail/customer-service-and-etiquette-for-retail"
            element={<CoursePage pageId={"customer-service-and-etiquette-for-retail"} />}
          />
          <Route
            path="/course-detail/recruitment-training-for-employers"
            element={<CoursePage pageId={"recruitment-training-for-employers"} />}
          /> */}
            {/* <Route
            path="/course-details/*"
            element={<CoursePage />}
          /> */}
            <Route path="/blog-standard" element={<BLogStandard />} />
            <Route path="/single-blog" element={<SingleBlog />} />
            <Route path="/blogs" element={<Blogs />} />
            <Route
              path="/blogs/blog-detail/:blogSlug"
              element={<BlogDetail />}
            />
            <Route
              path="/information-policy/:policySlug"
              element={<PolicyDetail />}
            />
            <Route path="/contacts" element={<ContactUs />} />
            <Route path="/thanks" element={<Thanks />} />
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </div>
      </BrowserRouter>
    </homeContext.Provider>
  );
}

export default App;
