import React from "react";
import brand1 from "../assets/images/all-img/brands/1.svg";
import brand2 from "../assets/images/all-img/brands/2.svg";
import brand3 from "../assets/images/all-img/brands/3.svg";
import brand4 from "../assets/images/all-img/brands/4.svg";
import brand5 from "../assets/images/all-img/brands/5.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";  // Import autoplay styles
import { Navigation, Pagination, Autoplay } from "swiper";  // Import Autoplay module

const Brands = ({ section_padding_bottom, section_padding_top, data }) => {
  console.log('brands data is', data)
  return (
    <div
      className={`brands-area ${section_padding_bottom} ${section_padding_top}`}
    >
      <div className="container">
        {data?.showTitle && (
        <div className="text-center text-black text-2xl font-medium mb-[50px]">
          {/* <span className=" shape-bg mini">Trusted</span> By 1k+ Company Around
          The World! */}{data?.title}
        </div>
        )}
        <Swiper
          slidesPerView={4}
          spaceBetween={40} // Set gap between items
          loop={true} // Enable infinite scrolling
          autoplay={{
            delay: 3000, // Slide changes every 3 seconds
            disableOnInteraction: false, // Continue autoplay after user interaction
          }}
          modules={[Navigation, Pagination, Autoplay]} // Add Autoplay module
          className="mySwiper"
        >
          {data?.listingItems?.map((image, index) => (
            <SwiperSlide key={index}>
              <img
                src={image?.image}
                alt={`Slide ${index + 1}`}
                style={{ width: "100%", borderRadius: "8px" }}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default Brands;
