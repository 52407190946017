import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import seoService from "../../services/seo";

const SEO = ({ seo }) => {
  const location = useLocation();
  const path = location.pathname; // Extract the current path
  const [seoData, setSeoData] = useState(null);

  useEffect(() => {
    const fetchSEOData = async () => {
      if (!seo) {
        // Only fetch data if `seo` prop is not provided
        try {
          const seoResponse = await seoService.get(path);
          setSeoData(seoResponse.data); // Save the response in state
        } catch (error) {
          console.error("Failed to fetch SEO data:", error);
        }
      }
    };

    fetchSEOData();
  }, [path, seo]); // Run only when `path` or `seo` changes

  // Use either the `seo` prop or the fetched `seoData`
  const effectiveSeo = seo || seoData;

  if (!effectiveSeo) {
    // return null; // Return nothing while waiting for data
    return (
      <Helmet>
        <title>India Institute Of Career Development</title>
      </Helmet>
    );
  }

  return (
    <Helmet>
      <title>{effectiveSeo.pageTitle}</title>
      <meta name="description" content={effectiveSeo.pageDescription} />
      <meta name="keywords" content={effectiveSeo.pageKeywords} />
    </Helmet>
  );
};

// const SEO = React.memo(async ({ seo }) => {
//   const location = useLocation(); // Provides the current location object
//   const path = location.pathname; // Extract the current path
//   console.log("888 path in seo", path);

//   if (seo) {
//     return (
//       <Helmet>
//         <title>{seo.pageTitle}</title>
//         <meta name="description" content={seo.pageDescription} />
//         <meta name="keywords" content={seo.pageKeywords} />
//       </Helmet>
//     );
//   }

//   const seoResponse = await seoService.get(path);

//   if (seoResponse) {
//     console.log("888 response", seoResponse);
//   }

//   // if (data) {
//   //   return (
//   //     <Helmet>
//   //       <title>{data.pageTitle}</title>
//   //       <meta name="description" content={data.pageDescription} />
//   //       <meta name="keywords" content={data.pageKeywords} />
//   //     </Helmet>
//   //   );
//   // }
//   // return null;
// });

SEO.propTypes = {
  seo: PropTypes.shape({
    pageTitle: PropTypes.string,
    pageDescription: PropTypes.string,
    pageKeywords: PropTypes.string,
  }),
};

SEO.defaultProps = {
  seo: null,
};

export default SEO;
