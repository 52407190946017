import {
  blog1,
  blog2,
  blog3,
  c1,
  c2,
  c3,
  c4,
  c5,
  c6,
  c7,
  c8,
  c9,
  c10,
  c11,
  c12,
  c13,
  counter1,
  counter2,
  counter3,
  counter4,
  t1,
  t2,
  t3,
  t4,
  t5,
  t6,
  t7,
  t8,
  team1,
  team2,
  team3,
  team4,
  team5,
  team6,
  team7,
  team8,
  trainer,
  r1,
  r2,
  r3,
  r4,
  r5
} from "./images";

export const achivementCounter = [
  {
    icon: counter1,
    num: 82 + "k",
    sector: "Enrolled Students",
  },
  {
    icon: counter2,
    num: 460,
    sector: "Academic Programs",
  },
  {
    icon: counter3,
    num: 20,
    sector: "Certified Studens",
  },
  {
    icon: counter4,
    num: 200,
    sector: "Award Winnig",
  },
];

export const blogArticleData = [
  {
    title: "Fashion and Luxury Fashion in a Changing",
    img: c1,
  },
  {
    title: "Creative Writing Through Storytelling",
    img: c2,
  },
  {
    title: "Product Manager Learn The Skills & Job",
    img: c3,
  },
  {
    title: "The Power of Podcast for Storytelling",
    img: c4,
  },
];

export const courseData = [
  {
    id: [0, "marketing", "design"],
    post: "Art & desing",
    title: "Applied Banking and Banking Operations",
    price: "$29.28",
    img: c1,

    ratings: "4.5",
    time: "4h 30m",
    lesson: "2 Lessons",
    slug: "applied-banking-and-banking-operations",
  },
  {
    id: [0, "design", "finance"],
    post: ["Development"],
    price: "Free",
    title: "Placement Training ",
    img: c2,
    ratings: "4.5",
    time: "4h 30m",
    lesson: "2 Lessons",
    slug: "placement-training",
  },
  {
    id: [0, "marketing", "design"],
    post: "Drawing",
    price: "$72.39",
    title: "Cyber Security With Ethical Hacking",
    img: c3,
    ratings: "4.5",
    time: "4h 30m",
    lesson: "2 Lessons",
    slug: "cyber-security-with-ethical-hacking",
  },
  {
    id: [0, "marketing"],
    post: "Tecknology",
    price: "$49.33",
    title: "Cloud Computing with Amazon Web Services (AWS) ",
    img: c4,
    ratings: "4.5",
    time: "4h 30m",
    lesson: "2 Lessons",
    slug: "cloud-computing-with-amazon-web-services",
  },
  {
    id: [0, "finance", "design"],
    post: "Data science",
    title: "Business Presentation ",
    price: "Free",
    img: c5,
    ratings: "4.5",
    time: "4h 30m",
    lesson: "2 Lessons",
    slug: "business-presentation",
  },
  {
    id: [0, "design", "finance"],
    post: "Development",
    title: "Sales Training ",
    price: "$84",
    img: c6,
    ratings: "4.5",
    time: "4h 30m",
    lesson: "2 Lessons",
    slug: "sales-training",
  },
];

export const courseDataInPage = [
  {
    id: [0, "marketing", "design"],
    post: "Art & desing",
    title: "Applied Banking and Banking Operations",
    price: "$29.28",
    img: c1,

    ratings: "4.5",
    time: "4h 30m",
    lesson: "2 Lessons",
    url: 'applied-banking-and-banking-operations'
  },
  {
    id: [0, "design", "finance"],
    post: ["Development"],
    price: "Free",
    title: "Placement Training ",
    img: c2,
    ratings: "4.5",
    time: "4h 30m",
    lesson: "2 Lessons",
    url: 'placement-training'
  },
  {
    id: [0, "marketing", "design"],
    post: "Drawing",
    price: "$72.39",
    title: "Cyber Security With Ethical Hacking",
    img: c3,
    ratings: "4.5",
    time: "4h 30m",
    lesson: "2 Lessons",
    url: 'cyber-security-with-ethical-hacking'
  },
  {
    id: [0, "marketing"],
    post: "Tecknology",
    price: "$49.33",
    title: "Cloud Computing with Amazon Web Services (AWS) ",
    img: c4,
    ratings: "4.5",
    time: "4h 30m",
    lesson: "2 Lessons",
    url: 'cloud-computing-with-amazon-web-services'
  },
  {
    id: [0, "finance", "design"],
    post: "Data science",
    title: "Business Presentation ",
    price: "Free",
    img: c5,
    ratings: "4.5",
    time: "4h 30m",
    lesson: "2 Lessons",
    url: 'business-presentation'
  },
  {
    id: [0, "design", "finance"],
    post: "Development",
    title: "Sales Training ",
    price: "$84",
    img: c6,
    ratings: "4.5",
    time: "4h 30m",
    lesson: "2 Lessons",
    url: 'sales-training'
  },
  {
    id: [0, "design", "finance"],
    post: "Development",
    title: "Data Analytics",
    price: "$29.28",
    img: c7,
    ratings: "4.5",
    time: "4h 30m",
    lesson: "2 Lessons",
    url: 'data-analytics'
  },
  {
    id: [0, "design", "finance"],
    post: "Development",
    title: "DevOps",
    price: "$29.28",
    img: c8,
    ratings: "4.5",
    time: "4h 30m",
    lesson: "2 Lessons",
    url: 'devops'
  },
  {
    id: [0, "design", "finance"],
    post: "Development",
    title: "Data Science & AI ML",
    price: "$29.28",
    img: c9,
    ratings: "4.5",
    time: "4h 30m",
    lesson: "2 Lessons",
    url: 'data-science&Ai-ml'
  },
  {
    id: [0, "design", "finance"],
    post: "Development",
    title: "Python Full Stack",
    price: "$29.28",
    img: c10,
    ratings: "4.5",
    time: "4h 30m",
    lesson: "2 Lessons",
    url: 'python-full-stack'
  },
  {
    id: [0, "design", "finance"],
    post: "Development",
    title: "Business English",
    price: "$29.28",
    img: c11,
    ratings: "4.5",
    time: "4h 30m",
    lesson: "2 Lessons",
    url: 'business-english'
  },
  {
    id: [0, "design", "finance"],
    post: "Development",
    title: "Customer Service And Etiquette For Retail",
    price: "$29.28",
    img: c12,
    ratings: "4.5",
    time: "4h 30m",
    lesson: "2 Lessons",
    url: 'customer-service-and-etiquette-for-retail'
  },
  {
    id: [0, "design", "finance"],
    post: "Development",
    title: "Recruitment Training For Employers",
    price: "$29.28",
    img: c13,
    ratings: "4.5",
    time: "4h 30m",
    lesson: "2 Lessons",
    url: 'recruitment-training-for-employers'
  },
];

export const topicsData = [
  { logo: t1, title: "Data Science", quantity: "68 Courses" },
  { logo: t2, title: "UI/UX Design", quantity: "UI/UX Design" },
  { logo: t3, title: "Modern Physics", quantity: "68 Courses" },
  { logo: t4, title: "Music Production", quantity: "45 Courses" },
  { logo: t5, title: "Data Science", quantity: "68 Courses" },
  { logo: t6, title: "Finances", quantity: "68 Courses" },
  { logo: t7, title: "Global Science", quantity: "75 Courses" },
  { logo: t8, title: "Game Design", quantity: "12 Courses" },
];

export const teamData = [
  { img: team1, name: "Erics Widget", title: "Developer" },
  { img: team2, name: "Daniel Steven", title: "Developer" },
  { img: team3, name: "Nelson Decosta", title: "Data Specialist" },
  { img: team4, name: "Selina Gomez", title: "Business" },
];
export const instructorData = [
  { img: team5, name: "Erics Widget", title: "UI/UX Designer" },
  { img: team6, name: "Daniel Steven", title: "Developer" },
  { img: team7, name: "Nelson Decosta", title: "Digital Marketer" },
  { img: team8, name: "Selina Gomez", title: "UI/UX Designer" },
];

export const blogsData = [
  {
    img: blog1,
    type: "education",
    position: "admin",
    date: "jan 29, 2022",
    title: "Selina Gomez",
  },
  {
    img: blog2,
    type: "education",
    position: "admin",
    date: "jan 29, 2022",
    title: "Selina Gomez",
  },
  {
    img: blog3,
    type: "education",
    position: "admin",
    date: "jan 29, 2022",
    title: "Selina Gomez",
  },
];

export const courseDetails = [
  {
    id: "applied-banking-and-banking-operations",
    post: "Data Science",
    title: "Applied Banking and Banking Operations",
    trainer: "Md Samim Hossan",
    trainerImg: trainer,
    lastUpdate: "10 February,2022",
    description:
      "This placement-oriented course equips aspiring banking professionals with practical skills in banking operations, regulatory compliance, and customer service. Delivered in online, offline, and hybrid modes, it offers flexibility for learners. The curriculum focuses on real-world applications, digital banking trends, fraud prevention, and career preparation, including mock interviews and expert-led sessions. With industry-aligned training and 100% placement assistance, participants gain job readiness for roles in retail banking, compliance, and financial services. Whether starting or advancing a career in banking, this course provides comprehensive knowledge, practical tools, and networking opportunities to excel in the dynamic banking sector.",
    price: "$29.28",
    img: c1,
    whatWillLearn: [
      "Master core banking operations and regulatory frameworks.",
      "Gain expertise in digital banking and financial fraud prevention.",
      "Build confidence with mock interviews and career guidance.",
      "Learn from experienced banking and finance industry experts.",
    ],
    ratings: "4.5",
    time: "4h 30m",
    lesson: "2 Lessons",
  },
  {
    id: 'placement-training',
    post: "Data Science",
    price: "Free",
    title: "Placement Training ",
    img: c2,
    ratings: "4.5",
    time: "4h 30m",
    lesson: "2 Lessons",
    trainer: "Md Samim Hossan",
    trainerImg: trainer,
    lastUpdate: "10 February,2022",
    description:
      "The Placement Training Program is designed to prepare final-year students and fresh graduates for the competitive job market. With a focus on soft skills, interview techniques, and workplace dynamics, this course offers practical training to help you confidently navigate recruitment processes. The program covers essential areas such as resume writing, communication skills, body language, and industry-specific training tailored to various sectors like banking, IT, and healthcare. Participants will benefit from mock interviews, group discussions, and personalized feedback. Post-training support ensures continuous guidance, empowering you to stand out and secure your dream job.",
    whatWillLearn: [
      "Resume writing, communication, and interview techniques.",
      "Industry-specific knowledge and personalized training.",
      "Mock interviews and real-world scenario practice.",
      "Post-training support and career development tips.",
    ],
  },
  {
    id: 'cyber-security-with-ethical-hacking',
    post: "Data Science",
    price: "$72.39",
    title: "Cyber Security With Ethical Hacking",
    img: c3,
    ratings: "4.5",
    time: "4h 30m",
    lesson: "2 Lessons",
    trainer: "Md Samim Hossan",
    trainerImg: trainer,
    lastUpdate: "10 February,2022",
    description:
      "The Cyber Security with Ethical Hacking: course equips you with essential skills to thrive in the rapidly growing IT industry. This comprehensive program blends cutting-edge theoretical knowledge with hands-on practical experience. Learn the latest in cyber security trends and ethical hacking techniques from industry experts. The course includes a 2-month live project internship, allowing you to apply your skills in real-world scenarios. Whether you’re beginning your career or looking to upgrade your expertise, this course offers the tools and experience you need to succeed in a competitive IT landscape.",
    whatWillLearn: [
      "Key concepts in cyber security and ethical hacking",
      "Hands-on techniques for real-world security challenges",
      "Industry-specific tools and methodologies for effective protection",
      "How to apply knowledge through a 2-month internship",
    ],
  },
  {
    id: 'cloud-computing-with-amazon-web-services',
    post: "Data Science",
    price: "$49.33",
    title: "Cloud Computing with Amazon Web Services (AWS) ",
    img: c4,
    ratings: "4.5",
    time: "4h 30m",
    lesson: "2 Lessons",
    trainer: "Md Samim Hossan",
    trainerImg: trainer,
    lastUpdate: "10 February,2022",
    description:
      "The Cloud Computing with Amazon Web Services (AWS) course equips you with essential skills to excel in the rapidly growing IT field. Designed for both beginners and experienced professionals, this course offers advanced technical training in cloud computing. With expert-led sessions and a 2-month live project internship, you will gain hands-on experience with AWS technologies, preparing you to tackle real-world challenges. Stay ahead of the competition and enhance your career opportunities in IT by mastering cloud computing essentials and best practices.",
    whatWillLearn: [
      "In-depth understanding of cloud computing concepts and AWS services.",
      "Practical experience through a 2-month live project internship.",
      "Advanced AWS tools for cloud infrastructure management and security.",
      "Real-world skills to solve industry challenges in cloud environments.",
    ],
  },
  {
    id: 'business-presentation',
    post: "Data science",
    title: "Business Presentation ",
    price: "Free",
    img: c5,
    ratings: "4.5",
    time: "4h 30m",
    lesson: "2 Lessons",
    trainer: "Md Samim Hossan",
    trainerImg: trainer,
    lastUpdate: "10 February,2022",
    description:
      "The Business Presentation Course is designed to help professionals, students, and future leaders master the art of impactful presentations. This course focuses on enhancing communication skills to deliver persuasive and compelling messages with confidence. Participants will learn how to structure content, utilize storytelling, and effectively use visual aids to captivate and influence any audience. By mastering body language, audience engagement techniques, and simplifying complex concepts, this course ensures that every presentation leaves a lasting impression. With personalized feedback and real-world applications, you will gain the skills to excel in business presentations and drive success in various professional settings.",
    whatWillLearn: [
      "Craft persuasive and engaging presentations with confidence",
      "Structure content for clarity and audience engagement",
      "Utilize storytelling and visual aids for impact",
      "Master non-verbal communication and audience interaction",
    ],
  },
  {
    id: 'sales-training',
    post: "Data Science",
    title: "Sales Training ",
    price: "$84",
    img: c6,
    ratings: "4.5",
    time: "4h 30m",
    lesson: "2 Lessons",
    trainer: "Md Samim Hossan",
    trainerImg: trainer,
    lastUpdate: "10 February,2022",
    description:
      "Our Sales Training course equips professionals with the essential skills and techniques needed to close more deals and drive business growth. Designed for customization, the program is tailored to meet the specific needs of your industry, products, and sales goals. Participants will gain actionable insights into effective sales strategies, communication, negotiation, and lead generation. By leveraging CRM tools and advanced sales methods, your team will improve productivity, strengthen client relationships, and enhance overall performance. This course ensures continuous support, empowering your sales team to consistently meet and exceed their targets.",
    whatWillLearn: [
      "Advanced sales strategies and techniques for success.",
      "Effective communication and objection-handling skills.",
      "How to generate and qualify high-quality leads.",
      "Mastery of CRM tools and sales automation systems.",
    ],
  },
  {
    id: 'data-analytics',
    post: "Data Science",
    price: "$49.33",
    title: "Data Analytics",
    img: c7,
    ratings: "4.5",
    time: "4h 30m",
    lesson: "2 Lessons",
    trainer: "Md Samim Hossan",
    trainerImg: trainer,
    lastUpdate: "10 February,2022",
    description:
      "The Data Analytics course is designed to equip you with advanced technical skills for the rapidly evolving IT industry. Taught by industry experts, this course offers up-to-date knowledge in data analysis and its applications. Along with theoretical learning, you’ll participate in a 2-month live project internship, providing hands-on experience to enhance your career prospects. Whether you are starting your journey in IT or looking to advance your expertise, this course prepares you to thrive in a competitive and dynamic IT landscape, giving you the tools and experience to succeed.",
    whatWillLearn: [
      "Advanced data analytics techniques and tools.",
      "Practical experience through a live project internship.",
      "Industry-relevant knowledge for a competitive edge.",
      "Skills to excel in the dynamic IT field.",
    ],
  },
  {
    id: 'devops',
    post: "Data Science",
    title: "DevOps",
    price: "$29.28",
    img: c8,
    ratings: "4.5",
    time: "4h 30m",
    lesson: "2 Lessons",
    trainer: "Md Samim Hossan",
    trainerImg: trainer,
    lastUpdate: "10 February,2022",
    description:
      "Our DevOps course is designed to equip you with the advanced skills needed to thrive in today's fast-paced IT industry. Led by industry experts, this course offers a comprehensive curriculum that covers the latest DevOps practices and tools. In addition to theoretical knowledge, you will gain hands-on experience through a 2-month live project internship, providing valuable insights and practical expertise. Whether you are just beginning your IT journey or looking to advance your career, this course will give you the edge to succeed in the competitive tech landscape.",
    whatWillLearn: [
      "Master DevOps tools and practices.",
      "Gain hands-on experience with live projects.",
      "Develop skills for efficient IT operations.",
      "Enhance career opportunities in the IT industry.",
    ],
  },
  {
    id: 'data-science&Ai-ml',
    post: "Data Science",
    title: "Data Science & AI ML",
    price: "$29.28",
    img: c9,
    ratings: "4.5",
    time: "4h 30m",
    lesson: "2 Lessons",
    trainer: "Md Samim Hossan",
    trainerImg: trainer,
    lastUpdate: "10 February,2022",
    description:
      "Our Data Science & AI ML course is designed to meet the growing demand for skilled IT professionals. With industry experts leading the way, this course offers advanced technical knowledge in Data Science and AI Machine Learning, preparing you for a competitive IT landscape. It includes a 2-month live project internship to provide real-world experience and practical skills. Whether you're new to the field or seeking to enhance your expertise, this course equips you with the tools to thrive in the rapidly evolving world of IT and machine learning.",
    whatWillLearn: [
      "Core concepts of Data Science and AI Machine Learning",
      "Hands-on experience through a 2-month live project",
      "Practical skills to excel in real-world IT scenarios",
      "Insights from industry-leading experts in the field",
    ],
  },
  {
    id: 'python-full-stack',
    post: "Data Science",
    title: "Python Full Stack",
    price: "$29.28",
    img: c10,
    ratings: "4.5",
    time: "4h 30m",
    lesson: "2 Lessons",
    trainer: "Md Samim Hossan",
    trainerImg: trainer,
    lastUpdate: "10 February,2022",
    description:
      "In today’s fast-paced IT landscape, advanced knowledge in Data Science, AI, and Machine Learning is essential. Our Data Science & AI ML course is designed to equip you with the cutting-edge technical skills needed to thrive in the competitive IT industry. Led by industry experts, this course blends theory and practice, offering in-depth knowledge alongside a 2-month live project internship. This hands-on experience ensures you gain valuable skills to excel in real-world scenarios. Whether you're new to IT or seeking to enhance your expertise, this course will prepare you for success in the rapidly evolving tech world.",
    whatWillLearn: [
      "Data Science fundamentals and machine learning techniques.",
      "Practical experience through a 2-month live project internship.",
      "Advanced AI applications and algorithms.",
      "Tools and technologies used in modern IT industry.",
    ],
  },
  {
    id: 'business-english',
    post: "Data Science",
    title: "Business English",
    price: "$29.28",
    img: c11,
    ratings: "4.5",
    time: "4h 30m",
    lesson: "2 Lessons",
    trainer: "Md Samim Hossan",
    trainerImg: trainer,
    lastUpdate: "10 February,2022",
    description:
      "In the global business world, effective communication is essential. Our Business English Course is designed for professionals, graduates, and students aiming to enhance their communication skills. This course focuses on real-world applications such as writing professional emails, delivering impactful presentations, and engaging in workplace conversations. Through interactive methods like role-plays, case studies, and simulations, participants will develop confidence in both speaking and writing. With expert trainers and tailored content, this course offers practical vocabulary, cross-cultural communication techniques, and strategies for various business settings, ensuring you're prepared for any professional challenge.",
    whatWillLearn: [
      "Professional email writing and presentation delivery techniques.",
      "Cross-cultural communication and negotiation strategies.",
      "Tailoring communication styles for diverse business scenarios.",
      "Mastery of business-specific vocabulary and workplace phrases.",
    ],
  },
  {
    id: 'customer-service-and-etiquette-for-retail',
    post: "Data Science",
    title: "Customer Service And Etiquette For Retail",
    price: "$29.28",
    img: c12,
    ratings: "4.5",
    time: "4h 30m",
    lesson: "2 Lessons",
    trainer: "Md Samim Hossan",
    trainerImg: trainer,
    lastUpdate: "10 February,2022",
    description:
      "The Customer Service and Etiquette for Retail course is designed to empower your team with the essential skills to deliver exceptional service that fosters customer loyalty and boosts sales. Tailored to your specific products, customer base, and brand values, this course focuses on building effective communication, handling customer complaints, and providing a memorable shopping experience. Participants will master customer etiquette, upselling techniques, and how to represent your brand positively. By fostering a customer-first mindset, this training ensures your team excels in creating lasting customer impressions and driving business growth.",
    whatWillLearn: [
      "Master customer etiquette and professional communication.",
      "Develop skills to handle difficult customers confidently.",
      "Techniques for building long-term customer loyalty.",
      "Upselling and cross-selling to enhance sales.",
    ],
  },
  {
    id: 'recruitment-training-for-employers',
    post: "Data Science",
    title: "Recruitment Training For Employers",
    price: "$29.28",
    img: c13,
    ratings: "4.5",
    time: "4h 30m",
    lesson: "2 Lessons",
    trainer: "Md Samim Hossan",
    trainerImg: trainer,
    lastUpdate: "10 February,2022",
    description:
      "The Recruitment Training for Employers program is designed to help hiring managers, HR professionals, and business leaders enhance their recruitment processes. This comprehensive course covers essential skills, including sourcing candidates, conducting effective interviews, and streamlining hiring workflows. Participants will also learn how to eliminate bias, implement fair practices, and leverage technology for candidate evaluation. Additionally, the course focuses on improving employer branding and onboarding strategies, as well as tracking recruitment performance with key metrics. By the end of this course, employers will be equipped to attract, assess, and retain top talent to build a strong workforce.",
    whatWillLearn: [
      "Streamline recruitment processes to save time and increase efficiency.",
      "Master sourcing high-quality candidates through various channels.",
      "Conduct effective, bias-free interviews for better hiring decisions.",
      "Leverage technology to enhance candidate evaluation and selection.",
    ],
  },
];

export const testimonialData = [
  {
    name: 'Ferig Watson',
    date: 'Oct 09, 2021',
    content: 'There Are Many Variations Of Passages Of Lorem Ipsum Available, But The Majority Have Suffered Alteration In Some Form',
    img: r1
  },
  {
    name: 'Ferig Watson',
    date: 'Oct 09, 2021',
    content: 'There Are Many Variations Of Passages Of Lorem Ipsum Available, But The Majority Have Suffered Alteration In Some Form',
    img: r2
  },
  {
    name: 'Ferig Watson',
    date: 'Oct 09, 2021',
    content: 'There Are Many Variations Of Passages Of Lorem Ipsum Available, But The Majority Have Suffered Alteration In Some Form',
    img: r3
  },
  {
    name: 'Ferig Watson',
    date: 'Oct 09, 2021',
    content: 'There Are Many Variations Of Passages Of Lorem Ipsum Available, But The Majority Have Suffered Alteration In Some Form',
    img: r4
  },
  {
    name: 'Ferig Watson',
    date: 'Oct 09, 2021',
    content: 'There Are Many Variations Of Passages Of Lorem Ipsum Available, But The Majority Have Suffered Alteration In Some Form',
    img: r5
  },
]
