import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CourseDetails from "./CourseDetails";
import Header from "../../Header";
import Footer from "../../Footer";
import PageBanner from "../../PageBanner";
import { courseDetails } from "../../../constant/dummyData";
import pagesService from "../../../services/pages";
import SEO from "../../SEO/SEO";

const CoursePage = ({ pageId }) => {
  const params = useParams();
  const [data, setData] = useState();
  const [relatedCourses, setRelatedCourses] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const isCourseDetail = location.pathname.includes("course-detail");
  const [loading, setLoading] = useState(false);
  const [relatedLoading, setRelatedLoading] = useState(false);
  const [metaData, setMetaData] = useState();

  // useEffect(() => {
  //   const filteredCourse = courseDetails?.filter(
  //     (item) => item?.id == params.courseId
  //   );
  //   setData({ ...filteredCourse[0] });
  //   const related = courseDetails?.filter(
  //     (item) => item?.id != params.courseId
  //   );
  //   setRelatedCourses(related);
  // }, [params.courseId]);

  const getCourseBySlug = async () => {
    setLoading(true);

    try {
      const data = await pagesService.getBySlug(params.courseId);
      if (data?.status == 404) {
        setData();
        setLoading(false);
        return;
      }
      console.log("fetched course data", data);
      if (data?.data?.name) {
        setData(data?.data);
        setLoading(false);
        setMetaData({
          pageTitle: data?.data?.metatitle,
          pageDescription: data?.data?.metadescription,
          pageKeywords: data?.data?.metakeywords?.join(","),
        });
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const getRelatedCourses = async () => {
    setRelatedLoading(true);
    try {
      const data = await pagesService.getAll(
        `?category=674df5891beff24912948432`
      );
      if (data?.status == 404) {
        setRelatedCourses([]);
        setRelatedLoading(false);
        return;
      }
      console.log("fetched courses data", data);
      if (data?.data) {
        setRelatedCourses(
          data?.data?.filter((item) => item?.slug != params.courseId)
        );
        setRelatedLoading(false);
      } else {
        setRelatedLoading(false);
      }
    } catch (err) {
      setRelatedLoading(false);
    }
  };

  const getRelatedCorporateSolutions = async () => {
    setRelatedLoading(true);
    try {
      const data = await pagesService.getAll(
        `?category=6759416fea264a6404d9e127`
      );
      if (data?.status == 404) {
        setRelatedCourses([]);
        setRelatedLoading(false);
        return;
      }
      console.log("fetched courses data", data);
      if (data?.data) {
        setRelatedCourses(
          data?.data?.filter((item) => item?.slug != params.courseId)
        );
        setRelatedLoading(false);
      } else {
        setRelatedLoading(false);
      }
    } catch (err) {
      setRelatedLoading(false);
    }
  };

  console.log("courses data is", data);

  useEffect(() => {
    if (isCourseDetail) {
      getRelatedCourses();
    } else {
      getRelatedCorporateSolutions();
    }
  }, [isCourseDetail, params.courseId]);

  useEffect(() => {
    getCourseBySlug();
  }, [params.courseId]);

  return (
    <>
      <SEO seo={metaData && metaData} />
      <Header />
      <PageBanner
        title={isCourseDetail ? "Course Details" : "Corporate Solution Details"}
        pageName={data?.title}
        showBreadCrumb={true}
      />
      <CourseDetails
        data={data}
        relatedCourses={relatedCourses}
        isCourseDetail={isCourseDetail}
        loading={loading}
        relatedLoading={relatedLoading}
      />
      <Footer />
    </>
  );
};

export default CoursePage;
