import React from "react";
import Feature from "../Feature";
import Footer from "../Footer";
import Header from "../Header";
import Accordion from "../HomeThreeComponents/Accordion";
import Counter from "../HomeTwoComponents/Counter";
import PageBanner from "../PageBanner";
import Testimonials from "../Testimonials";
import About from "../About";
import AboutTwo from "../HomeThreeComponents/About";
import Platform from "../Platform";
import Team from "../Team";
import Blogs from "../Blogs";

const AboutOne = () => {
  return (
    <>
      <Header />
      <PageBanner title={"About Us"} pageTitle="About Us" num={1} />
      <About />
      <Platform />
      <AboutTwo />
      {/* <Feature /> */}
      {/* <Counter /> */}
      <Testimonials />
      {/* <Team />
      <Accordion /> */}
      {/* <Team />
      <Blogs /> */}
      <Footer />
    </>
  );
};

export default AboutOne;
