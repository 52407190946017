import React from "react";
import CourseDetails from "../CourseDetails";
import Footer from "../Footer";
import Header from "../Header";
import PageBanner from "../PageBanner";
import { useParams } from "react-router-dom";

const SingleCourse = () => {

  const {slug} = useParams();
console.log('slug value', slug)

  return (
    <>
      <Header />
      <PageBanner title={"Course Details"} pageName={"Course Details"} />
      <CourseDetails slug={slug} />
      <Footer />
    </>
  );
};

export default SingleCourse;
