import React, { useEffect, useState } from "react";
import Feature from "../Feature";
import Footer from "../Footer";
import Header from "../Header";
import Accordion from "../HomeThreeComponents/Accordion";
import Counter from "../HomeTwoComponents/Counter";
import PageBanner from "../PageBanner";
import Testimonials from "../Testimonials";
import About from "../About";
import AboutTwo from "../HomeThreeComponents/About";
import Platform from "../Platform";
import Team from "../Team";
import Blogs from "../Blogs";
import { insIcon1,insIcon2,insIcon3, reviewStars } from "../../constant/images";
import { testimonialData } from "../../constant/dummyData";
import Testimonial from "../HomeThreeComponents/TestimonialTwo";
import Videotestimonials from "./VideoTestimonials";
import testimonialService from "../../services/testimonials";
import { useNavigate } from "react-router-dom";



const TestimonialsPage = () => {


  return (
    <>
      <Header />
      <PageBanner title={"Testimonial"} pageTitle="Testimonial" />
      <div className="flex justify-center pb-10">
      <div className=" mt-14 w-[34%]">
      <div className="column-title text-center mt-4 mb-16">
      Real Stories <span className="shape-bg">Real Success</span>
          </div>
              {/* <ul className="comments space-y-4 mt-2 pt-10 pb-10 px-2">
                {testimonialData?.map((item)=>(
                <li className="block border-b border-[#ECECEC] pb-6 mt-6 last:border-0 last:pb-0">
                  <div className="flex">
                    <div className="flex-none">
                      <div className="h-[72px] w-[72px] rounded-full mr-6">
                        <img
                          src={item.img}
                          alt=""
                          className="w-full block h-full object-contain rounded-full"
                        />
                      </div>
                    </div>
                    <div className="flex-1">
                      <div className="flex flex-wrap justify-between mb-2">
                        <div>
                          <span className="text-xl  font-semibold text-black block mb-1">
                            {item.name}
                          </span>
                          <span className=" block">{item.date}</span>
                        </div>
                        <div className="h-[43px]">
                        <img src={reviewStars} />
                        </div>
                      </div>
                      <p className="break-normal">
                        {item.content}
                      </p>
                    </div>
                  </div>

                </li>
                ))} */}
                {/* <li className="block">
                  <div className="flex">
                    <div className="flex-none">
                      <div className="h-[72px] w-[72px] rounded-full mr-6">
                        <img
                          src={insIcon2}
                          alt=""
                          className="w-full block h-full object-contain rounded-full"
                        />
                      </div>
                    </div>
                    <div className="flex-1">
                      <div className="flex flex-wrap justify-between mb-2">
                        <div>
                          <span className="text-xl  font-semibold text-black block mb-1">
                            Ferira Watson
                          </span>
                          <span className=" block">Oct 09, 2021</span>
                        </div>
                        <a
                          href="#"
                          className=" inline-flex px-3 py-1 rounded text-secondary bg-[#E3F9F6] hover:text-white hover:bg-secondary h-[35px]
                            items-center space-x-2 leading-[1]"
                        >
                          <span className=" text-xl leading-[1] top-[3px] relative">
                            <iconify-icon
                              icon="charm:forward"
                              rotate="180deg"
                            ></iconify-icon>
                          </span>
                          <span className=" leading-[1]">Reply</span>
                        </a>
                      </div>
                      <p>
                        There are many variations of passages of Lorem Ipsum
                        available, but the majority have suffered alteration in
                        some form
                      </p>
                    </div>
                  </div>
                </li> */}
              {/* </ul> */}
              {/* {testimonials?.filter((item) => item?.testimonialType == "CONTENT")?.length > 0 && (
              <Testimonial testimonials = {testimonials} />
              )} */}
            </div>
            </div>

            <Videotestimonials/>
 
      {/* <Blogs /> */}
      <Footer />
    </>
  );
};

export default TestimonialsPage;
