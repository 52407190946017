import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import { useNavigate } from "react-router-dom";
import { Modal, Button, Form, Input, message, notification } from "antd";
import contactUsService from "../../services/contact-us";

const ContactModal = ({ isModalOpen, setIsModalOpen }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const naviget = useNavigate();

  const checkvalid = (value) => {
    if (value == "" || value == undefined) {
      return true;
    } else {
      return false;
    }
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    console.log("form values", name, email, description, contact);
    if (
      checkvalid(name) ||
      checkvalid(email) ||
      checkvalid(contact) ||
      checkvalid(description)
    ) {
      message.error({
        content: "Please enter all fields",
        duration: 5, // Duration in seconds
      });
      setLoading(false);
      return;
    } else {
      setEmail("");
      setName("");
      setDescription("");
      setLoading(false);
    }

    const templateParams = {
      name: name,
      email: email,
      phoneNumber: contact,
      messages: description,
    };
    console.log(templateParams);
    // message.success({
    //   content: "Submitted Successfully",
    //   duration: 5, // Duration in seconds
    // });
    try {
      const data = await contactUsService.create(templateParams);
      console.log("contact us created");
      if (data?.success) {
        // message.success("Submitted Successfully");
        naviget("/thanks");
      }
    } catch (error) {
      error.message?.map((err) =>
        notification.error({
          message: err,
        })
      );
    }
  };
  return (
    <Modal
      // title="Form Modal"
      open={isModalOpen}
      onCancel={() => setIsModalOpen(false)}
      footer={null}
    >
      <form
        onSubmit={handleSubmit}
        className=" md:grid-cols-2 grid grid-cols-1 gap-[30px] mt-6 "
      >
        <div>
          <input
            type="text"
            className=" from-control"
            placeholder="Name*"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div>
          <input
            type="email"
            className=" from-control"
            placeholder="Email*"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="md:col-span-2 col-span-1">
          <input
            className=" from-control"
            placeholder="Contact*"
            value={contact}
            onChange={(e) => setContact(e.target.value)}
          />
        </div>
        <div className="md:col-span-2 col-span-1">
          <textarea
            className=" from-control"
            placeholder="Description*"
            rows="5"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          ></textarea>
        </div>
        <button class="btn btn-primary mt-[10px]" type="submit" name="submit">
          {loading ? "sending.." : "Join Now"}
        </button>
      </form>
    </Modal>
  );
};

export default ContactModal;
