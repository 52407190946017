/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {
  bs1,
  bs2,
  bs3,
  user3,
  comment,
  q,
  calender,
  clockIcon,
  fbIcon,
  pnIcon,
  twIcon,
  insIcon,
  rc3,
  rc1,
  insIcon1,
  insIcon3,
  insIcon2,
  search,
  rc2,
  insIcon4,
  insIcon5,
  insIcon6,
} from "../../../constant/images";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../../assets/svgs/LoadingSpinner";
import { InlineShareButtons } from "sharethis-reactjs";
import ShareButtons from "../../ShareButtons";

const BlogPost = ({ data, relatedBlogs, relatedBlogsLoading }) => {
  const navigate = useNavigate();
  return (
    <div className="nav-tab-wrapper tabs  section-padding">
      <div className="container">
        <div className="grid grid-cols-12 gap-[30px]">
          <div className="lg:col-span-8 col-span-12">
            <div className="bg-[#F8F8F8] rounded-md">
              <img src={data?.image} alt="" className=" rounded-t-md mb-10" />
              <div className="px-10 pb-10">
                <div className="flex  flex-wrap  xl:space-x-10 space-x-5 mt-6 mb-6">
                  <a className=" flex items-center space-x-2" href="#">
                    <img src={user3} alt="" />
                    <span>{data?.addedByName && data?.addedByName}</span>
                  </a>
                  <a className=" flex items-center space-x-2" href="#">
                    <img src={calender} alt="" />
                    <span>
                      {data?.createdAt &&
                        new Intl.DateTimeFormat("en-US", {
                          day: "2-digit",
                          month: "short",
                          year: "numeric",
                        }).format(new Date(data?.createdAt))}
                    </span>
                  </a>
                </div>
                <h3>{data?.name}</h3>
                <p
                  dangerouslySetInnerHTML={{
                    __html: data?.description,
                  }}
                  className="mt-6 course-description"
                ></p>
                {/* <ul className="list-disc marker:text-secondary space-y-4 mt-6">
                  <li className="">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </li>
                  <li className="">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </li>
                  <li className="">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </li>
                </ul> */}
                <ShareButtons />
                {/* <div className="grid xl:grid-cols-2 grid-cols-1  gap-5 md:mt-14 mt-8 ">
                  <ul className="flex space-x-3  items-center  ">
                    <li className=" text-black font-semibold">Share On:</li>

                    {[fbIcon, pnIcon, twIcon, insIcon].map((item, indx) => (
                      <li key={indx}>
                        <a href="#" className="flex h-8 w-8">
                          <img src={item} alt="" />
                        </a>
                      </li>
                    ))}
                  </ul>
                </div> */}
              </div>
            </div>
          </div>

          <div className="lg:col-span-4 col-span-12">
            <div className="sidebarWrapper space-y-[30px]">
              <div className="wdiget widget-recent-post">
                <h4 className=" widget-title">Related Blogs</h4>
                <ul className="list">
                  {/* <li className=" flex space-x-4 border-[#ECECEC] pb-6 mb-6 last:pb-0 last:mb-0 last:border-0 border-b">
                    <div className="flex-none ">
                      <div className="h-20 w-20  rounded">
                        <img
                          src={rc1}
                          alt=""
                          className=" w-full h-full object-cover rounded"
                        />
                      </div>
                    </div>
                    <div className="flex-1 ">
                      <div className="mb-1 font-semibold text-black">
                        How to Manage Ads For Clients The Right Way
                      </div>
                      <a className=" text-secondary font-semibold" href="#">
                        Read More
                      </a>
                    </div>
                  </li> */}
                  {relatedBlogsLoading && <LoadingSpinner />}
                  {!relatedBlogsLoading &&
                    relatedBlogs?.slice(0, 5)?.map((item) => {
                      return (
                        <li
                          key={item._id}
                          className=" flex space-x-4 border-[#ECECEC] pb-6 mb-6 last:pb-0 last:mb-0 last:border-0 border-b"
                        >
                          <div className="flex-none ">
                            <div className="h-auto w-20  rounded">
                              <img
                                src={item.image}
                                alt=""
                                className=" w-full h-auto object-cover rounded"
                              />
                            </div>
                          </div>
                          <div className="flex-1 ">
                            <div className="mb-1 font-semibold text-black ellipsis">
                              {item.name}
                            </div>
                            <a
                              onClick={() =>
                                navigate(`/blogs/blog-detail/${item.slug}`)
                              }
                              className=" text-secondary font-semibold cursor-pointer"
                              // href={`/blogs/blog-detail/${item.slug}`}
                            >
                              Read More
                            </a>
                          </div>
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogPost;
