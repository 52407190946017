import React from 'react'
import { c1 } from '../../../constant/images'

function CourseData() {
  return (
    <div>
      <h1>Course Details</h1>
      <h2>Applied Technology</h2>
      <img src={c1} />
    </div>
  )
}

export default CourseData
